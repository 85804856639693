@import "@angular/material/prebuilt-themes/indigo-pink.css";

body {
    margin: 0;
}

a:hover {
    text-decoration: none !important;
}

.mat-menu-panel {
    min-height: 30px !important;
    
    .mat-menu-item {
        height: 30px !important;
        line-height: 30px !important;
    }
}

.clickable {
    cursor: pointer;
    outline: none;
}

.logo img {
    height: 50px;
    width: auto;
}

// Snackbar
.mat-mdc-snack-bar-container {
    &.success-snackbar {
        --mdc-snackbar-container-color: rgb(92, 170, 85);
    }

    &.error-snackbar {
        --mdc-snackbar-container-color: #ff0033;
        --mdc-snackbar-supporting-text-weight: bold;
    }
  }

// Tab scroll
.mat-tab-body-wrapper {
    max-height: calc(100vh - 130px);
}

.mat-error {
    font-size: 0.8em;
}

.pdf {
    color: rgb(255, 0, 0);
}

.increase-popover-width {
    max-width: 100%; /* Max Width of the popover (depending on the container!) */
    //max-width: 700px;
}

.form-group {
    margin-bottom: 1rem;
}